import React from "react"
import SectionTitle from "../../atoms/SectionTitle"
import ContentSection from "../../molecules/ContentSection"
import ImageUrlRenderer from "../../atoms/ImageUrlRenderer"
import ProgressBar from "../../atoms/ProgressBar"

const CompanyValueSection = ({ data }) => {
  const imageUrl = ImageUrlRenderer({ img: `about--see-experience-bg` })
  return (
    <div className="mt-16 mb-10">
      <div className="flex flex-col md:flex-row items-stretch">
        <div className="flex-[1] flex flex-col items-center justify-center bg-primary-light">
          <div className="md:max-w-[580px] w-full ml-auto pl-[20px] pr-[20px] lg:pr-[80px] pt-16 pb-10">
            <SectionTitle
              title={data.leftContent.title}
            />
            {data.leftContent.contentSections.map((section, index) => (
              <ContentSection
                key={index}
                title={section.title}
                titleEC={section.titleEC}
                description={section.description}
                list={section.list}
                image={section.image}
                imageEC={section.imageEC}
              />
            ))}
          </div>
        </div>
        <div
          className="flex-[1] flex flex-col items-center justify-center bg-cover bg-center bg-secondary"
          style={{ backgroundImage: `url(${imageUrl})` }}
        >
          <div className="md:max-w-[580px] w-full mr-auto  pr-[20px] pl-[20px] lg:pl-[80px] py-16">
            <h2 className="font-ubuntu text-[40px] leading-[1.2em] font-[700] text-white max-w-[260px]">
              {data.rightContent.title}
            </h2>
            <div className="flex flex-col gap-[40px] mt-16">
              {data.rightContent.progressBars.map((item, index) => (
                <ProgressBar
                key={"progressBar" + index}
                  label={item.title}
                  progress={item.progress}
                  showPercentage={false}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CompanyValueSection
