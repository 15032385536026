import React from "react"
import ImageUrlRenderer from "../../atoms/ImageUrlRenderer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlay } from "@fortawesome/free-solid-svg-icons"

const SeeOurWorkingVideoSection = ({ data }) => {
  const imageUrl = ImageUrlRenderer({ img: data.bgImage })
  return (
    <div
      className="bg-cover bg-center bg-secondary py-28 mt-16"
      style={{ backgroundImage: `url(${imageUrl})` }}
    >
      <div className="cc-section--horizontal-space flex flex-col items-center justify-center gap-[30px]">
        <div className="w-[80px] h-[80px] text-[20px] cursor-pointer mb-5 bg-[#FFFFFF90] text-primary flex items-center justify-center text-center rounded-[50%]">
          <FontAwesomeIcon icon={faPlay} className="" />
        </div>
        <h2 className="font-ubuntu text-[30px] md:text-[40px] leading-[1.2em] font-[700] text-white text-center">
          {data.title}
        </h2>
        <p className="text-[16px] leading-[1.4em] font-[200] text-white text-center font-[400] max-w-[570px] mx-auto">
          {data.description}
        </p>
      </div>
    </div>
  )
}

export default SeeOurWorkingVideoSection
