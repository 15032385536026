// Team Members
import {
  faFacebook,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons"
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons"

// Team Members
export const teamMembers = [
  {
    image: `team-member`,
    name: `Eion Morgan`,
    designation: `Provider Agent`,
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi condimentum leo at facilisis tincidunt. Ut a neque ac libero ornare mattis. In vel posuere quam. Sed ut aliquam justo.`,
    contactDetails: [
      {
        icon: faEnvelope,
        link: `#`,
      },
      {
        icon: faPhone,
        link: `#`,
      },
    ],
    socialLinks: [
      {
        icon: faFacebook,
        link: `#`,
      },
      {
        icon: faTwitter,
        link: `#`,
      },
      {
        icon: faLinkedin,
        link: `#`,
      },
    ],
  },
  {
    image: `team-member`,
    name: `Eion Morgan`,
    designation: `Provider Agent`,
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi condimentum leo at facilisis tincidunt. Ut a neque ac libero ornare mattis. In vel posuere quam. Sed ut aliquam justo.`,
    contactDetails: [
      {
        icon: faEnvelope,
        link: `#`,
      },
      {
        icon: faPhone,
        link: `#`,
      },
    ],
    socialLinks: [
      {
        icon: faFacebook,
        link: `#`,
      },
      {
        icon: faTwitter,
        link: `#`,
      },
      {
        icon: faLinkedin,
        link: `#`,
      },
    ],
  },
  {
    image: `team-member`,
    name: `Eion Morgan`,
    designation: `Provider Agent`,
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi condimentum leo at facilisis tincidunt. Ut a neque ac libero ornare mattis. In vel posuere quam. Sed ut aliquam justo.`,
    contactDetails: [
      {
        icon: faEnvelope,
        link: `#`,
      },
      {
        icon: faPhone,
        link: `#`,
      },
    ],
    socialLinks: [
      {
        icon: faFacebook,
        link: `#`,
      },
      {
        icon: faTwitter,
        link: `#`,
      },
      {
        icon: faLinkedin,
        link: `#`,
      },
    ],
  },
  {
    image: `team-member`,
    name: `Eion Morgan`,
    designation: `Provider Agent`,
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi condimentum leo at facilisis tincidunt. Ut a neque ac libero ornare mattis. In vel posuere quam. Sed ut aliquam justo.`,
    contactDetails: [
      {
        icon: faEnvelope,
        link: `#`,
      },
      {
        icon: faPhone,
        link: `#`,
      },
    ],
    socialLinks: [
      {
        icon: faFacebook,
        link: `#`,
      },
      {
        icon: faTwitter,
        link: `#`,
      },
      {
        icon: faLinkedin,
        link: `#`,
      },
    ],
  },
  {
    image: `team-member`,
    name: `Eion Morgan`,
    designation: `Provider Agent`,
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi condimentum leo at facilisis tincidunt. Ut a neque ac libero ornare mattis. In vel posuere quam. Sed ut aliquam justo.`,
    contactDetails: [
      {
        icon: faEnvelope,
        link: `#`,
      },
      {
        icon: faPhone,
        link: `#`,
      },
    ],
    socialLinks: [
      {
        icon: faFacebook,
        link: `#`,
      },
      {
        icon: faTwitter,
        link: `#`,
      },
      {
        icon: faLinkedin,
        link: `#`,
      },
    ],
  },
  {
    image: `team-member`,
    name: `Eion Morgan`,
    designation: `Provider Agent`,
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi condimentum leo at facilisis tincidunt. Ut a neque ac libero ornare mattis. In vel posuere quam. Sed ut aliquam justo.`,
    contactDetails: [
      {
        icon: faEnvelope,
        link: `#`,
      },
      {
        icon: faPhone,
        link: `#`,
      },
    ],
    socialLinks: [
      {
        icon: faFacebook,
        link: `#`,
      },
      {
        icon: faTwitter,
        link: `#`,
      },
      {
        icon: faLinkedin,
        link: `#`,
      },
    ],
  },
  {
    image: `team-member`,
    name: `Eion Morgan`,
    designation: `Provider Agent`,
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi condimentum leo at facilisis tincidunt. Ut a neque ac libero ornare mattis. In vel posuere quam. Sed ut aliquam justo.`,
    contactDetails: [
      {
        icon: faEnvelope,
        link: `#`,
      },
      {
        icon: faPhone,
        link: `#`,
      },
    ],
    socialLinks: [
      {
        icon: faFacebook,
        link: `#`,
      },
      {
        icon: faTwitter,
        link: `#`,
      },
      {
        icon: faLinkedin,
        link: `#`,
      },
    ],
  },
  {
    image: `team-member`,
    name: `Eion Morgan`,
    designation: `Provider Agent`,
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi condimentum leo at facilisis tincidunt. Ut a neque ac libero ornare mattis. In vel posuere quam. Sed ut aliquam justo.`,
    contactDetails: [
      {
        icon: faEnvelope,
        link: `#`,
      },
      {
        icon: faPhone,
        link: `#`,
      },
    ],
    socialLinks: [
      {
        icon: faFacebook,
        link: `#`,
      },
      {
        icon: faTwitter,
        link: `#`,
      },
      {
        icon: faLinkedin,
        link: `#`,
      },
    ],
  },
]
