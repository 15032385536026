export const aboutusPage = {
  title: `Specialist Disability Accommodation (SDA) at Care Casa: Experience Superior Living`,
  description: `Count on us for independence and comfort in our SDA homes, meeting your desires. `,
  googleReviewText: `Care Casa’s SDA home services have completely transformed my life. I have gained the independence and comfort I desire. I highly recommend them!`,
}

// AboutUs Section
export const aboutusSection = {
  sectionMainImage: {
    path: `home--aboutus-section-image`,
    alt: `home--aboutus-section-image`,
  },
  sectionTitle: `<span>About</span> us`,
  description: `Care Casa is a high-quality NDIS SDA housing solution provider that aims to boost autonomy and a top-tier lifestyle for people with impairments. Our mission is to develop comfortable and accessible living spaces with a touch of inclusivity. We work with clients, families, and communities to support them with personalised solutions. We believe in engagement and creating a welcoming environment where individuals with disabilities can thrive and gain their full potential.`,
  iconBoxes: [
    {
      iconImage: {
        path: `about-us-section-icon1`,
        alt: `about-us-section-icon1`,
      },
      title: `Quality SDA Homes Meeting Needs`,
      description: `High-quality, accessible homes are designed with modern accessibility features and assistive technologies to provide maximum ease.`,
    },
    {
      iconImage: {
        path: `about-us-section-icon2`,
        alt: `about-us-section-icon2`,
      },
      title: `Expertise in NDIS Coordination`,
      description: `Our mastery will navigate you through a hassle-free NDIS process, maximising your benefits.`,
    },
    {
      iconImage: {
        path: `about-us-section-icon3`,
        alt: `about-us-section-icon3`,
      },
      title: `Strong Community Focus`,
      description: `Our supportive and inclusive community approach helps participants prosper and develop meaningful, long-lasting connections.`,
    },
  ],
  btnText: `Read More`,
  btnLink: `/about-us`,
}

// Company Value Section
export const companyValueSection = {
  leftContent: {
    title: `<span>Company</span> Value`,
    contentSections: [
      {
        description: `Company values develop a cohesive culture, promote stakeholder trust, and guarantee alignment with the company's aims. Care Casa centres on respect, independence, innovation and compassion. We firmly believe that treating all participants is equal and must be cared for with dignity and respect. We aim to empower individuals with disabilities to live independently per their aspirations. We strive for state-of-the-art facilities to improve the lifestyle of SDA participants. For a supportive environment, we offer care and support.`,
        list: [
          `Respect - Treat NDIS participants with dignity and respect`,
          `Independence - Inspire NDIS participants to live unassisted`,
          `Innovation - Aspire to adopt modern technologies`,
          `Compassion - Make a supportive community with care and support`,
        ],
      },
    ],
  },
  rightContent: {
    bgImage: `about--see-experience-bg`,
    title: `Let’s See Our Experience`,
    progressBars: [
      {
        title: `Respect`,
        progress: 75,
      },
      {
        title: `Independence`,
        progress: 55,
      },
      {
        title: `Innovation`,
        progress: 95,
      },
      {
        title: `Compassion`,
        progress: 85,
      },
    ],
  },
}

// See Our Working Video Section
export const seeOurWorkingVideoSection = {
  bgImage: `about--working-video-placeholder-image`,
  title: `Watch Our Video`,
  description: `Get an exclusive look at how we bring care to life. See our team in action and the inspiring moments that make Care Assure unique.`,
}
