import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { houseDetailsSection, teamSection } from "../content/Common"
import { contactSection } from "../content/Common"
import ContentSectionWithImage from "../components/common/ContentSectionWithImage"
import ContactSection from "../components/common/ContactSection"
import InnerHeader from "../components/molecules/InnerHeader"
import {
  aboutusPage,
  aboutusSection,
  companyValueSection,
  seeOurWorkingVideoSection,
} from "../content/AboutUs"
import HouseDetailsSection from "../components/common/HouseDetailsSection"
import CompanyValueSection from "../components/pages/AboutUs/CompanyValueSection"
import SeeOurWorkingVideoSection from "../components/pages/AboutUs/SeeOurWorkingVideoSection"
import TeamSection from "../components/pages/AboutUs/TeamSection"

const AboutUs = () => (
  <Layout>
    <Seo title="About Us" />

    <InnerHeader
      title={aboutusPage.title}
      googleReviewText={aboutusPage.googleReviewText}
      description={aboutusPage.description}
    />

    <ContentSectionWithImage data={aboutusSection} />

    <CompanyValueSection data={companyValueSection} />

    <HouseDetailsSection data={houseDetailsSection} />

    <SeeOurWorkingVideoSection data={seeOurWorkingVideoSection} />

    {/* <TeamSection data={teamSection} /> */}

    <ContactSection data={contactSection} sectionTitleAlignment={"center"} />
  </Layout>
)

export default AboutUs
